/* eslint-disable camelcase */
import transformJson from 'utils/transformJson'
import searchResultsSchema from '../schemas/searchResultsSchema'
import { cookingAPIClient } from 'api/services/cookingAPI/client/v2'
import { RESULTS_SIZE } from 'pages/search/constants'
import { RECIPE_BOX_RESULTS_SIZE } from 'components/search/RecipeBoxResults/constants'
import { $TSFixMe } from 'types'
import { QueryFnOptions } from 'utils/fetchClient/v2'
import { RECIPE_BOX_RESULTS_PER_PAGE } from 'utils/pagination'

const makeCookingSearchRequest = async (
  baseEndpoint: string,
  searchParams: $TSFixMe,
  search_abra_overrides: $TSFixMe,
  serverNYTSCookie: $TSFixMe = null,
  isStateful: boolean = false,
  funcOptions?: Partial<QueryFnOptions>,
) => {
  let endpoint = baseEndpoint
  const {
    tags,
    page = 1,
    timeLte,
    timeGte,
    sort,
    folderId,
    deviceType,
  } = searchParams
  endpoint = `${endpoint}&page=${page}`
  if (tags && tags.length) {
    endpoint = `${endpoint}&tags=${tags.join(',')}`
  }
  if (timeGte) {
    endpoint = `${endpoint}&time_gte=${timeGte}`
  }
  if (timeLte) {
    endpoint = `${endpoint}&time_lte=${timeLte}`
  }
  if (sort) {
    endpoint = `${endpoint}&sort=${sort}`
  }
  if (folderId) {
    endpoint = `${endpoint}&collection_id=${folderId}`
  }
  if (search_abra_overrides) {
    endpoint = `${endpoint}&abra-overrides=${search_abra_overrides}`
  }
  if (deviceType) {
    endpoint = `${endpoint}&device_type=${deviceType}`
  }
  // only return crops that we use to render search result recipe cards to reduce response size
  endpoint = `${endpoint}&include_crops=ipad_mediumThreeByTwo440,card`
  const transformResponse = (res: $TSFixMe) => {
    if (isStateful) {
      return transformJson(res, searchResultsSchema)
    }
    return transformJson(res.body, searchResultsSchema)
  }
  const NYTSHeaderVal = serverNYTSCookie
  // using HttpOnly cookie client-side
  const shouldIncludeCredentials = NYTSHeaderVal === null
  return await cookingAPIClient(
    endpoint,
    { transformResponse, ...funcOptions },
    shouldIncludeCredentials,
    NYTSHeaderVal,
    isStateful,
  )
}

export const RECIPE_BOX_SEARCH_QUERY_KEY = 'recipe_box_search_results_get'

export const getRecipeBoxResults = async (
  query = '',
  userId: $TSFixMe,
  searchParams: $TSFixMe,
  search_abra_overrides: $TSFixMe,
  showRecipeBoxResults: boolean = false,
  funcOptions?: Partial<QueryFnOptions>,
) => {
  const perPage = showRecipeBoxResults
    ? RECIPE_BOX_RESULTS_PER_PAGE
    : RECIPE_BOX_RESULTS_SIZE
  return await makeCookingSearchRequest(
    `v2/users/${userId}/search/recipe_box_search?q=${query}&per_page=${perPage}`,
    searchParams,
    search_abra_overrides,
    null,
    true,
    funcOptions,
  )
}

export const getSearchResults = async (
  query = '',
  searchParams: $TSFixMe,
  search_abra_overrides: $TSFixMe,
  serverNYTSCookie: $TSFixMe,
) => {
  return await makeCookingSearchRequest(
    `v2/search?q=${query}&per_page=${RESULTS_SIZE}`,
    searchParams,
    search_abra_overrides,
    serverNYTSCookie,
  )
}

export const getBylineSearchResults = async (
  query = '',
  searchParams: $TSFixMe,
  serverNYTSCookie: $TSFixMe,
) => {
  return await makeCookingSearchRequest(
    `v2/search_recipes_by_byline?q=${query}&per_page=${RESULTS_SIZE}`,
    searchParams,
    null,
    serverNYTSCookie,
    true,
  )
}
