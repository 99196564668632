import { ModalStateProps } from 'contexts/modal/ModalContext'

import {
  PAYWALL,
  STATIC_PAYWALL,
  REGIWALL,
  REGI_MOBILE_PROMPT,
  GROCERY_LIST_MODAL,
  NEWSLETTER_MODAL,
  APP_UPSELL_MODAL,
  WHITE_BACKGROUND,
  WHITE_BACKGROUND_WITHOUT_SITENAV,
  VIDEO_MODAL,
  BLACK_BACKGROUND_OPAQUE,
  TRANSPARENT_BACKGROUND,
  SNACK_BAR_TOAST,
  CREATE_FOLDER_MODAL,
  STEP_MEDIA_MODAL,
  ORGANIZE_FOLDER_MODAL,
  RENAME_FOLDER_MODAL,
  CONFIRMATION_FOLDER_MODAL,
  TERMS_OF_SERVICE_MODAL,
} from './constants'
import React from 'react'

const modalDetails = new Map<string, ModalStateProps>()

/**
 * @typedef {Array} MODALS
 * @description The modals array is a predefined array that contains a modals attributes.
 * A modal can have the following attributes
 * @type {string} id - What type of modal is it?
 * @type {Element} component - The react component for the modal.
 * @type {bool} isCloseable - Can the user close the modal?
 * @type {string} backgroundType - When the modal is open, what color should the background be?
 * @type {bool} noScroll - Prevents the user from scrolling the page if the modal is open.
 * @type {string} className - A helpful attribute that allows user to override the parent modal css if needed.
 *
 * @description These attributes can be overwritten to achieve desired functionality.
 * For example, say an action calls for the regi modal to be triggered BUT the user should be able to close it.
 * @example
 * showModal({ type: REGIWALL, modalFields: { isCloseable: true } })
 */
const MODALS: ModalStateProps[] = [
  {
    id: PAYWALL,
    className: 'paywall-modal-wrapper',
    isCloseable: false,
    isFullScreen: false,
    component: React.lazy(
      () => import('components/shared/Modals/PayWallModal/index'),
    ),
    backgroundType: WHITE_BACKGROUND,
  },
  {
    id: STATIC_PAYWALL,
    isCloseable: false,
    component: React.lazy(
      () => import('components/shared/Modals/PayWallModal/index'),
    ),
    backgroundType: WHITE_BACKGROUND,
  },
  {
    id: REGIWALL,
    className: 'regi-modal-wrapper',
    isCloseable: false,
    isFullScreen: false,
    noScroll: true,
    component: React.lazy(
      () => import('components/shared/Modals/RegiModal/index'),
    ),
  },
  {
    id: REGI_MOBILE_PROMPT,
    isCloseable: false,
    component: React.lazy(
      () => import('components/shared/Modals/RegiMobilePrompt/index'),
    ),
  },
  {
    id: GROCERY_LIST_MODAL,
    className: 'grocery-list-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      () => import('components/shared/Modals/GroceryListModal/index'),
    ),
  },
  {
    id: NEWSLETTER_MODAL,
    className: 'newsletter-modal-wrapper',
    isCloseable: false,
    component: React.lazy(
      () => import('components/shared/Modals/Onboarding/NewsletterModal/index'),
    ),
  },
  {
    id: APP_UPSELL_MODAL,
    className: 'app-upsell-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      () => import('components/shared/Modals/Onboarding/AppUpsellModal/index'),
    ),
  },
  {
    id: VIDEO_MODAL,
    className: 'video-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      () => import('components/shared/Modals/VideoModal/index'),
    ),
    backgroundType: BLACK_BACKGROUND_OPAQUE,
  },
  {
    id: SNACK_BAR_TOAST,
    className: 'snack-bar-toast-wrapper',
    isCloseable: false,
    backgroundType: TRANSPARENT_BACKGROUND,
    component: React.lazy(() => import('components/shared/Modals/Toast/index')),
  },
  {
    id: CREATE_FOLDER_MODAL,
    className: 'folder-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      () => import('components/recipe-box/Modals/FolderModal/index'),
    ),
  },
  {
    id: STEP_MEDIA_MODAL,
    className: 'step-media-modal-wrapper',
    isCloseable: true,
    noScroll: true,
    backgroundType: WHITE_BACKGROUND_WITHOUT_SITENAV,
    component: React.lazy(
      () => import('components/recipe/Preparation/StepMediaModal'),
    ),
  },
  {
    id: ORGANIZE_FOLDER_MODAL,
    className: 'organize-folder-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      () => import('components/shared/Modals/OrganizeFolderModal'),
    ),
  },
  {
    id: RENAME_FOLDER_MODAL,
    className: 'folder-modal-wrapper',
    isCloseable: false,
    component: React.lazy(
      () => import('components/recipe-box/Modals/FolderModal/index'),
    ),
  },
  {
    id: CONFIRMATION_FOLDER_MODAL,
    className: 'folder-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      () => import('components/shared/Modals/ConfirmationModal/index'),
    ),
  },
  {
    id: TERMS_OF_SERVICE_MODAL,
    className: 'terms-of-service-modal-wrapper',
    isCloseable: true,
    component: React.lazy(
      // Update this to the correct component
      () => import('components/shared/Modals/OMATermsOfService/index'),
    ),
  },
]

const setModalDetails = () => {
  for (const modal of MODALS) {
    modalDetails.set(modal.id, modal)
  }
}

export const getModalDetails = (modalId: string) => {
  setModalDetails()
  return modalDetails.get(modalId)
}
